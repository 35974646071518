import IsMobile from "../components/IsMobile";
import s from "./Home.module.css";

function RepeaterInformation() {
	let isMobile = IsMobile();

	return (
		<div className={isMobile ? s.HomeMobile : s.Home}>
			<div className={s.title}>Repeater Information</div>
			<br />
			<br />
			840 REPEATER (ZL3CAR) = 438.400 Mhz TX . 433.400 Mhz RX TS1/TG 5303
			(Static) + TG 9 (Local) + TS2/TG 10 (Local)
			<br />
			<br />
			AIDANFIELD REPEATER (ZL3DVX) = 439.400 Mhz TX , 434.400 Mhz RX
			(TS1/TG 5303 + TG 6 Are Static Talkgroups)
			<br />
			<br />
			WOOLSTON REPEATER (ZL3DVR) = 439.575 Mhz TX , 434.575 Mhz RX (TS1/TG
			5303 + TG 6 Are Static Talkgroups)
			<br />
			<br />
			TIMARU REPEATER (ZL3UR) = 438.450 Mhz TX . 433.450 Mhz RX (TS1/TG
			5303)
			<br />
			<br />
			Waimate REPEATER (ZL3MX) = 439.100 Mhz TX . 434.100 Mhz RX TS1/TG
			5303 (Static) + TG 9 (Local) + TS2/TG 10 (Local)
			<br />
			<br />
			<strong>
				The frequency your mobile/portable radio must transmit on is the
				repeater RX frequency. E.g. your radio should transmit on
				433.400 Mhz and receive on 438.400 Mhz for the 840 repeater.
			</strong>
			<br />
			<br />
			<br />
			You will need to visit{" "}
			<a
				className={s.link}
				href="https://www.radioid.net/"
				target="_blank"
				rel="noreferrer"
			>
				https://www.radioid.net/
			</a>{" "}
			and obtain a DMR ID.
			<br />
			<br />
			<br />
			All Repeaters use COLOR CODE 1 (CC1)
			<br />
			<br />
			All Repeaters use TIMESLOT 1 (TS1) For Brandmeister Static/Dynamic
			ZL Talkgroups (6-XLX530D, 530-ZL, 5301-ZL1, 5302-ZL2, 5303-ZL3,
			5304-ZL4, 5305-Mt Grey) Talkgroup 9 TS1 local Talk on the Repeater
			<br />
			<br />
			All Repeaters use TIMESLOT 2 (TS2) For ZL TRBO/IPSC2 Dynamic
			Talkgroups (530-ZL, 8-ZK, 153-Sth Pacific Reflector) + (Any
			International Brandmeister Dynamic Talkgroups, for example: TG 91
			Worldwide, TG2350 UK, TG 3100 USA etc etc etc.) Talkgroup 10 TS2
			local Talk on the Repeater
			<br />
			<br />
			<br />
			<a
				className={s.link}
				href="https://www.google.com/maps/d/viewer?mid=1GE9SaceoCo0GxL3oLitjSUNWVmxeYAkv&ll=-43.721613988454145%2C173.21848294869127&z=9"
				target="_blank"
				rel="noreferrer"
			>
				Map of NZ digital repeater locations
			</a>
			<br />
			<br />
			<img
				className={s.image}
				src={require("../images/yellow.jpg")}
				alt="Radio"
			/>
			{isMobile ? <div className={s.bottomForScroll}></div> : <></>}
		</div>
	);
}

export default RepeaterInformation;
