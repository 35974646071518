import { useState } from "react";
import s from "./Dashboards.module.css";

// function formatDate(d) {
// 	var months = [
// 		"Jan",
// 		"Feb",
// 		"Mar",
// 		"Apr",
// 		"May",
// 		"Jun",
// 		"Jul",
// 		"Aug",
// 		"Sep",
// 		"Oct",
// 		"Nov",
// 		"Dec",
// 	];

// 	let date = new Date(d);
// 	let dateArr = date.toLocaleDateString("en-US").split("/");

// 	if (dateArr.length < 3) {
// 		return "Error";
// 	}

// 	return (
// 		months[Number(dateArr[0]) - 1] +
// 		" " +
// 		dateArr[1] +
// 		" " +
// 		dateArr[2] +
// 		" " +
// 		date.toLocaleTimeString()
// 	);
// }

function Dashboards() {
	const [dashboard, setDashboard] = useState(0);

	function getClass(id) {
		return dashboard === id ? s.tabSelected : s.tab;
	}

	function getDashboardClass(id) {
		return dashboard === id ? s.dashboard : s.dashboardHidden;
	}

	return (
		<div className={s.Dashboards}>
			<div className={s.topBar}>
				<div onClick={() => setDashboard(0)} className={getClass(0)}>
					ZL3DVX
					<br />
					Aidanfield
				</div>
				<div onClick={() => setDashboard(1)} className={getClass(1)}>
					ZL3DVR
					<br />
					Woolston
				</div>
				<div onClick={() => setDashboard(2)} className={getClass(2)}>
					ZL3CAR
					<br />
					840 Repeater
				</div>
				<div onClick={() => setDashboard(3)} className={getClass(43)}>
					ZL3UR
					<br />
					Cave Hill
				</div>
				<div onClick={() => setDashboard(4)} className={getClass(4)}>
					ZL3MX
					<br />
					Waimate
				</div>
			</div>

			<div className={s.container}>
				<iframe
					className={getDashboardClass(0)}
					src="https://zl3dvx.digitalradio.co.nz:51625/"
					title="Aidanfield"
				/>

				<iframe
					className={getDashboardClass(1)}
					src="https://zl3dvr.digitalradio.co.nz:83/"
					title="Woolston"
				/>

				<iframe
					className={getDashboardClass(2)}
					src="https://brandmeister.network/?page=device&id=530003"
					title="840"
				/>

				<iframe
					className={getDashboardClass(3)}
					src="https://brandmeister.network/?page=device&id=530022"
					title="Timaru"
				/>

				<iframe
					className={getDashboardClass(4)}
					// src="https://corsproxy.io/?http://dashboard.zl3mx.com:9000"
					src="https://brandmeister.network/?page=device&id=530037"
					title="Waimate"
					id="iframee"
				/>
			</div>
		</div>
	);
}

export default Dashboards;
